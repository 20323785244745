<template>
  <v-card>
    <v-card-title>アンケート</v-card-title>
    <div v-if="saved">
      <v-card-text>
        <v-alert outlined type="info">ありがとうございました！</v-alert>
      </v-card-text>
    </div>
    <div v-else>
      <v-card-text v-if="hired_">
        <v-row justify="center">
          <v-col align-self="center" cols="auto">
            <h1 :style="{ color: '#81C784' }">採用</h1>
          </v-col>
          <v-col align-self="center" cols="auto">
            <v-btn text color="primary" @click="hired_=false">
              不採用に変更
              <v-icon right>mdi-pencil-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">採用の理由を教えてください</v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox v-model="onTime" label="時間厳守"></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox v-model="energetic" label="元気に満ち溢れていた"></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox v-model="clean" label="清潔感があった"></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea v-model="otherReasonsForHiring" label="その他" rows="2" clearable></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn text color="primary" @click="save">保存</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row justify="center">
          <v-col align-self="center" cols="auto">
            <h1 :style="{ color: '#E57373' }">不採用</h1>
          </v-col>
          <v-col align-self="center" cols="auto">
            <v-btn text color="primary" @click="hired_=true">
              採用に変更
              <v-icon right>mdi-pencil-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">不採用の理由を教えてください</v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox v-model="noShow" label="ノーショー"></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox v-model="notEnergetic" label="元気がなかった"></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox v-model="notClean" label="清潔感に欠けていた"></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea v-model="otherReasonsForNotHiring" label="その他" rows="2" clearable></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn text color="primary" @click="save">保存</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'questionaire',
  props: {
    hired: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    saved: false,
    onTime: false,
    energetic: false,
    clean: false,
    otherReasonsForHiring: '',
    noShow: false,
    notEnergetic: false,
    notClean: false,
    otherReasonsForNotHiring: ''
  }),
  computed: {
    hired_: {
      get () {
        return this.hired
      },
      set (val) {
        this.$emit('onHiredChanged', val)
      }
    }
  },
  methods: {
    save () {
      const data = {
        hired: this.hired_,
        onTime: this.hired_ ? this.onTime : false,
        energetic: this.hired_ ? this.energetic : false,
        clean: this.hired_ ? this.clean : false,
        otherReasonsForHiring: this.hired_ ? this.otherReasonsForHiring : '',
        noShow: !this.hired_ ? this.noShow : false,
        notEnergetic: !this.hired_ ? this.notEnergetic : false,
        notClean: !this.hired_ ? this.notClean : false,
        otherReasonsForNotHiring: !this.hired_ ? this.otherReasonsForNotHiring : ''
      }
      this.$emit('onSaved', data)
      this.saved = true
    }
  },
  created () {
    this.hired_ = this.hired
  }
}
</script>
