<template>
  <v-container fluid>
    <questionaire
      v-if="loaded"
      :hired="hired"
      @onSaved="onSaved"
      @onHiredChanged="onHiredChanged"
    ></questionaire>
  </v-container>
</template>

<script>
import jwt from 'jsonwebtoken'

import { createQuestionaireResult, updateQuestionaireResult, getApplicantSecretKey } from '../helpers/firebase-functions'
import questionaire from '@/components/questionaire/Questionaire'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    questionaire
  },
  data: () => ({
    loaded: false,
    inError: false,
    ownerId: '',
    scenarioId: '',
    applicantId: '',
    hired: false,
    resultId: ''
  }),
  methods: {
    onHiredChanged (val) {
      this.hired = val
    },
    async onSaved (data) {
      data = {
        ...data,
        ownerId: this.ownerId,
        scenarioId: this.scenarioId,
        resultId: this.resultId
      }
      await updateQuestionaireResult(data)
    }
  },
  async created () {
    this.scenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

    const token = this.$route.params.questionaireToken
    const { ownerId, applicantId, hired } = jwt.decode(token)
    const { data: { secret } } = await getApplicantSecretKey({ ownerId, scenarioId: this.scenarioId, applicantId })

    try {
      jwt.verify(token, secret)
      this.ownerId = ownerId
      this.applicantId = applicantId
      this.hired = hired
      const { data: { resultId } } = await createQuestionaireResult({
        ownerId: this.ownerId,
        scenarioId: this.scenarioId,
        applicantId: this.applicantId,
        hired: this.hired
      })
      this.resultId = resultId
    } catch (err) {
      this.inError = true
    }
    this.loaded = true
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_QUESTIONAIRE)
  }
}
</script>
